<template>
  <div>
    <this-header />
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="container-new pb-5">
        <div class="container">
          <div class="d-flex align-items-center mb-1">
            <router-link
              v-if="UserData.role
                == 'banhuay'"
              :to="{ name: 'tanghuay-home' }"
            >
              <button
                class="bnt-home"
              >
                <i class="fas fa-home" />
              </button>
            </router-link>
            <div class="txt-right">
              <div class="font-weight-bolder">
                <span class="">ผลรางวัลทั้งหมด</span>
              </div>
            </div>
          </div>
          <b-row class="mt-2 text-center">
            <b-col lg="12">
              <div class="text-center header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="/th.svg"
                    alt=""
                    height="20"
                  >
                  <span class="YrVDG">
                    ผลสลากกินแบ่งรัฐบาล
                    {{ ResultThaiDate ? ResultThaiDate : "วว/ดด/ปปปป" }}
                  </span>
                </div>
                <b-row>
                  <b-col
                    lg="12"
                    cols="12"
                    class="mb-1"
                  >
                    <h3 class="m-0">
                      {{ ResultThai.R1 ? ResultThai.R1 : "xxx" }}
                    </h3>
                    <small>รางวัลที่ 1</small>
                  </b-col>

                  <b-col
                    lg="4"
                    cols="4"
                  >
                    <h3 class="m-0">
                      {{ ResultThai.F3N1 ? ResultThai.F3N1 : "xxx" }}
                      {{ ResultThai.F3N2 ? ResultThai.F3N2 : "xxx" }}
                    </h3>
                    <small>เลขหน้า 3 ตัว</small>
                  </b-col>

                  <b-col
                    lg="4"
                    cols="4"
                  >
                    <h3 class="m-0">
                      {{ ResultThai.B3N1 ? ResultThai.B3N1 : "xxx" }}
                      {{ ResultThai.B3N2 ? ResultThai.B3N2 : "xxx" }}
                    </h3>
                    <small>เลขท้าย 3 ตัว</small>
                  </b-col>

                  <b-col
                    lg="4"
                    cols="4"
                  >
                    <h3 class="m-0">
                      {{ ResultThai.D2 ? ResultThai.D2 : "xx" }}
                    </h3>
                    <small>สองตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              lg="6"
              cols="12"
            >
              <!-- Lotto THAI -->
              <div class="header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="https://www.เปิดสอบราชการ.com/img/articles/158634671920200416_154512.png"
                    alt=""
                    height="20"
                  >&nbsp;
                  <span class="YrVDG">
                    หวย ออมสิน
                    {{ ResultAomDate ? ResultAomDate : "วว/ดด/ปปปป" }}
                  </span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3 class="m-0">
                      {{ ResultAom.U3 ? ResultAom.U3 : "xxx" }}
                    </h3>
                    <small>3ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3 class="m-0">
                      {{ ResultAom.D2 ? ResultAom.D2 : "xx" }}
                    </h3>
                    <small>2ตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            <!-- End Lotto THAI -->
            </b-col>

            <b-col
              lg="6"
              cols="12"
            >
              <div class="header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="https://www.tode365.com/wp-content/uploads/2021/08/%E0%B8%98%E0%B8%81%E0%B8%AA.png"
                    alt=""
                    height="20"
                  >&nbsp;
                  <span class="YrVDG">
                    หวย ธกส.
                    {{ ResultTKSDate ? ResultTKSDate : "วว/ดด/ปปปป" }}</span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3 class="m-0">
                      {{ ResultTKS.U3 ? ResultTKS.U3 : "xxx" }}
                    </h3>
                    <small>3ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3 class="m-0">
                      {{ ResultTKS.D2 ? ResultTKS.D2 : "xx" }}
                    </h3>
                    <small>2ตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            <!-- End Lotto THAI -->
            </b-col>

            <b-col
              lg="12"
              cols="12"
            >
              <!-- Lotto ยี่กี -->
              <div class="header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="assets/themes/front2021/images/yigy_special.png"
                    alt=""
                    height="20"
                  >&nbsp;
                  <span class="YrVDG">
                    จับยี่กี รอบที่ {{ ResultYGRoundNo }} ประจำวันที่
                    {{
                      ResultYGLastDate ? ResultYGLastDate : "วว/ดด/ปปปป"
                    }}</span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3 class="m-0">
                      {{ ResultYGLast.U3 ? ResultYGLast.U3 : "xxx" }}
                    </h3>
                    <small>3ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3 class="m-0">
                      {{ ResultYGLast.D2 ? ResultYGLast.D2 : "xx" }}
                    </h3>
                    <small>2ตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            <!-- End Lotto THAI -->
            </b-col>
          </b-row>

          <!-- ---- -->
          <div class="section-wrap section-normal">
            <div class="mt-1 d-flex align-items-center">
              <img
                src="icon/vb2.svg"
                alt=""
                height="50"
              >
              <strong
                class="font-medium-2"
              >หวยหุ้นต่างประเทศ ประจำวันที่ {{ dateshow }}</strong>
            </div>
            <div class="row gutters-10">
              <div
                v-for="set in list"
                :key="set"
                class="col-md-4 col-lg-4"
              >
                <div class="box-lotto">
                  <div class="box-title">
                    <img
                      :src="`/assets/themes/front2021/images/${set.picture}`"
                      height="25"
                      width="25"
                    ><strong style="margin-left: 5px">{{ set.name }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="set.result1 > 0"
                        class="lot-num"
                      >
                        {{ set.result1 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="set.result2 > 0"
                        class="lot-num"
                      >
                        {{ set.result2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ยี่กี -->
          <!-- <div class="section-wrap section-normal mt-1">
            <div class="mb-1 mt-2">
              <strong
                class="font-medium-2"
              >จับยี่กี ประจำวันที่ {{ ResultYGTodayDate }}</strong>
            </div>
            <div class="row gutters-10">
              <div
                v-for="(yeeki, index) in ResultYGToday"
                :key="yeeki"
                class="col-md-4 col-lg-2"
              >
                <div class="box-lotto">
                  <div class="box-title yigy264">
                    <img
                      src="assets/themes/front2021/images/yigy_special.png"
                      class="img-title"
                    ><strong>จับยี่กี รอบที่ {{ index + 1 }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="yeeki.U3 > 0"
                        class="lot-num"
                      >
                        {{ yeeki.U3 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="yeeki.D2 > 0"
                        class="lot-num"
                      >
                        {{ yeeki.D2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
    </b-overlay>
    <this-footer />
  </div>
</template>

<script>
import {
//   BCard,
  BRow,
  BCol,
  //   BFormGroup,
  // BFormInput,
  // BButton,
  //   BPagination,
  //   BTable,
  //   BFormSelect,
  BOverlay,
  BIconController,
  VBTooltip,
  // BAvatar,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import 'animate.css'
import {
  required, min, length,
} from '@validations'
// import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ThisHeader from './component/ThisHeader.vue'
import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    // vSelect,
    // BFormSelect,
    // BCard,
    BRow,
    BCol,
    // BFormGroup,
    // BFormInput,
    // BButton,
    // BPagination,
    // BTable,
    // ValidationProvider,
    // ValidationObserver,
    BOverlay,
    BIconController,
    // BAvatar,
    ThisHeader,
    ThisFooter,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      min,
      length,
      ShowRound: false,
      show: false,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่', visible: true },
        {
          key: 'LottoHead',
          label: 'หวย',
          formatter: value => (this.NameLottoHead(value) || '-'),
          visible: true,
        },
        {
          key: 'LottoSubHead',
          label: 'ประเภท',
          visible: true,
        },
        { key: 'RoundData.CloseDateName', label: 'งวดวันที่', visible: true },
        { key: 'U3', label: 'เลขท้าย 3 ตัว(บน)', visible: true },
        { key: 'U2', label: 'เลขท้าย 2 ตัว(บน)', visible: true },
        { key: 'D2', label: 'เลขท้าย 2 ตัว(ล่าง)', visible: true },
        // { key: 'R1', label: 'รางวัลที่ 1', visible: true },
        // { key: 'F3N1', label: 'เลขหน้า 3 ตัว ที่ 1', visible: true },
        // { key: 'F3N2', label: 'เลขหน้า 3 ตัว ที่ 2', visible: true },
        // { key: 'B3N1', label: 'เลขท้าย 3 ตัว ที่ 1', visible: true },
        // { key: 'B3N2', label: 'เลขท้าย 3 ตัว ที่ 2', visible: true },
        {
          key: 'created_at',
          label: 'เวลาออกผล',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
          visible: true,
        },
        // { key: 'addby', label: 'แก้ไขโดย', visible: true },
        // { key: 'approveby', label: 'ยืนยันโดย', visible: true },
        // { key: 'actions', label: 'เครื่องมือ', visible: true },
      ],
      /* eslint-disable global-require */
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      RoundData: {},
      R1: null, // รางวัลที่ 1 [THG]
      F3N1: null, // เลขหน้า 3 ตัว ที่1 [THG]
      F3N2: null, // เลขหน้า 3 ตัว ที่2 [THG]
      B3N1: null, // เลขท้าย 3 ตัว ที่1 [THG]
      B3N2: null, // เลขท้าย 3 ตัว ที่2 [THG]
      U3: null, // 3 ตัวบน [THG]
      U2: null, // 2 ตัวบน [THG]
      D2: null, // 2 ตัวล่าง [THG]
      EditID: null,
      ResultThai: {},
      ResultThaiDate: null,
      ResultAom: {},
      ResultAomDate: null,
      ResultTKS: {},
      ResultTKSDate: null,
      list: [],
      ResultYGLast: {},
      ResultYGLastDate: null,
      ResultYGRoundNo: null,
      ResultYGToday: {},
      ResultYGTodayDate: moment().format('DD/MM/YYYY'),

    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  mounted() {
    this.GetDatas()
    this.GetResultThai(1, null)
    this.GetResultAom(3, null)
    this.GetResultTKS(2, null)
    this.GetResultYGLast()
    this.GetResultYG_Today()
  },
  methods: {
    async GetDatas() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/LottoTypeList',
        )

        if (ResData) {
          this.show = false
          this.list = ResData
          this.GetResult()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultThai(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultThai = ResData.mes
          this.ResultThaiDate = moment(
            ResData.mes.RoundData.CloseDate,
          ).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultAom(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultAom = ResData.mes
          this.ResultAomDate = moment(ResData.mes.RoundData.CloseDate).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultTKS(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultTKS = ResData.mes
          this.ResultTKSDate = moment(ResData.mes.RoundData.CloseDate).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResult() {
      this.dateshow = moment(this.date).format('DD/MM/YYYY')
      if (this.datechange) {
        this.date = this.datechange
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const items of this.list) {
        const params = {
          LottoTypeHead: items.TypeID,
          date: this.date,
        }
        this.Result(params)
      }
    },
    async Result(params) {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/GetResultRewardByType',
          { params },
        )
        if (ResData.success) {
          let Index = null
          // console.log(ResData.mes)
          Index = this.list.findIndex(
            ({ TypeID }) => TypeID === ResData.mes.LottoTypeHead,
          )

          this.list[Index].result1 = ResData.mes.U3
          this.list[Index].result2 = ResData.mes.D2
        }
      } catch (e) {
        console.log(e)
        // return null
      }
    },
    async GetResultYGLast() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/ShowLastYG',
        )

        if (ResData.success) {
          this.ResultYGLast = ResData.mes
          this.ResultYGRoundNo = ResData.RoundNo
          this.ResultYGLastDate = moment(
            ResData.mes.RoundData.CloseDate,
          ).format('DD/MM/YYYY')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultYG_Today() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/YGToDay',
        )

        if (ResData.success) {
          this.ResultYGToday = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
